var imsEvents = require('../../util/ims_events.js');

/**
 * A wrapper service for the IMS event service. This is used to attach handlers
 * to events fired by the IMS library.
 *
 * @ngInject
 */
function ImsEventsService() {
  return {
    addOnReadyHandler: addOnReadyHandler
  };

  /**
   * Adds an IMS onReady event handler.
   * @param {Function} func
   */
  function addOnReadyHandler(func) {
    imsEvents.addOnReadyHandler(func);
  }
}

module.exports = ImsEventsService;
